<template>
  <div class="product">
    <Hero></Hero>
    <div class="product__list">
      <ProductList
        v-for="(item, index) in category"
        :key="item.id"
        :category="item"
        :productArray="productItems[index]"
      ></ProductList>
    </div>
  </div>
</template>

<script>
import Hero from "@/components/product/Hero";
import ProductList from "@/components/product/ProductList";
import { productAPI } from "@/api/product/api";

export default {
  beforeRouteLeave(to, from, next) {
    if (this.$store.state.userCart.length != 0) {
      if (to.name == "Resell" || to.name == "Resell_Product") {
        this.$dialog["warning"]({
          title: "清空購物車",
          text:
            "您即將前往重銷商城，您要清空購物車嗎？（重銷商城與VIP商城之商品無法一同結帳）",
          persistent: true,
          actions: {
            true: { text: "清空" },
            false: { text: "取消" }
          }
        }).then(res => {
          if (res) {
            this.$store.dispatch("doCleanUserCart");
            next();
          } else {
            next();
          }
        });
      }
      next();
    }
    next();
  },
  data() {
    return {
      category: [],
      productItems: []
    };
  },
  components: {
    Hero,
    ProductList
  },
  created() {
    this.getProductList();
  },
  methods: {
    getProductList() {
      productAPI.get({ store_name: "VIP商城" }).then(res => {
        console.log(res.data)
        // 撈出隸屬商城的物件
        let items = res.data.data;
        // 撈出商品分類
        items.forEach(element => {
          if (!this.category.find(item => item == element.category_name))
            this.category.push(element.category_name);
        });
        // 依據商品分類再去撈出所屬分類的商品並塞入相對的陣列
        this.category.forEach((element, index) => {
          this.productItems[index] = items.filter(item => {
            return item.category_name == element;
          });
        });
      });
    }
  }
};
</script>
<style lang="scss">
@media (min-width: 768px) {
  .product__list {
    padding: 0 25vw;
  }
}
</style>
