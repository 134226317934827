<template>
  <div class="product">
    <v-container>
      <v-row justify="center">
        <v-col cols="12" md="4">
          <v-img :src="productInfo.product_photo" max-height="600"></v-img>
        </v-col>
        <v-col cols="12" md="4">
          <h1>{{ productInfo.product_name }}</h1>
          <!--  -->
          <v-row>
            <v-col
              class="ql-editor"
              v-html="productInfo.product_description"
            ></v-col>
          </v-row>
          <h3 class="red--text mb-5">商品售價：{{ productInfo.price }}</h3>
          <h3 class="red--text mb-5">商品PV值：{{ productInfo.pv }}</h3>
          <v-chip label color="white" class="px-0">
            <h2>數量：</h2>
            <v-icon
              color="green"
              @click="
                productInfo.amountShow != 1 ? productInfo.amountShow-- : null
              "
            >
              mdi-minus
            </v-icon>
            {{ productInfo.amountShow }}
            <v-icon color="red" @click="productInfo.amountShow++">
              mdi-plus
            </v-icon>
          </v-chip>
          <v-btn
            text
            large
            block
            color="primary"
            @click="addToCart(productInfo)"
            >加入購物車</v-btn
          >
        </v-col>
      </v-row>
      <v-row justify="center" align="center">
        <v-col class="ql-editor" v-html="productInfo.product_content"></v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { productAPI } from "@/api/product/api";
export default {
  data() {
    return {
      productInfo: {},
    };
  },
  created() {
    this.getProduct();
  },
  methods: {
    getProduct() {
      productAPI.getOne(this.$route.params.id).then((res) => {
        this.productInfo = res.data.data;
        this.$set(this.productInfo, "amount", 0);
        this.$set(this.productInfo, "amountShow", 1);
      });
    },
    addToCart(param) {
      if (
        this.$store.state.userCart.length != 0 &&
        this.$route.name == "Resell_Product"
      ) {
        for (let i = 0; i < this.$store.state.userCart.length; i++) {
          if (this.$store.state.userCart[i].store_name === "VIP商城") {
            return this.$Message.error(
              "重銷商城與VIP商城之商品無法一同結帳,請確認購物車商品"
            );
          }
        }
      }
      if (
        this.$store.state.userCart.length != 0 &&
        this.$route.name == "Vip_Store_Product"
      ) {
        for (let i = 0; i < this.$store.state.userCart.length; i++) {
          if (this.$store.state.userCart[i].store_name === "重銷商城") {
            return this.$Message.error(
              "重銷商城與VIP商城之商品無法一同結帳,請確認購物車商品"
            );
          }
        }
      }
      param.amount += param.amountShow;
      this.$store.dispatch("doAddItemToCart", param);
      this.$Message.success("成功加入購物車");
    },
  },
};
</script>
